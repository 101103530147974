<template>
  <div>
    <!-- error handelr -->
    <b-alert v-height-fade.appear fade :show="showDismissibleAlert" @dismissed="showDismissibleAlert = false"
      variant="danger">
      <h4 class="alert-heading">
        Alert
      </h4>
      <div class="alert-body">
        <ul v-for="values in errors_back" :key="values">
          <li v-for="value in values" :key="value">{{ value }}</li>
        </ul>
      </div>
    </b-alert>
    <b-card title="Cities">
      <b-row>
        <!-- sorting -->
        <b-col md="4" class="my-1">
          <b-form-group label="Sort By" label-cols-sm="3" label-align-sm="right" label-size="sm" label-for="sortBySelect"
            class="mb-0">

            <b-input-group size="sm">
              <b-form-select id="sortBySelect" @change="sortingTable" v-model="sortBy" :options="sortOptions"
                class="w-75">
              </b-form-select>
              <b-form-select @change="sortingTable" v-model="sortDesc" size="sm" :disabled="!sortBy" class="w-25">
                <option :value="false">
                  Asc
                </option>
                <option :value="true">
                  Desc
                </option>
              </b-form-select>
            </b-input-group>

            <b-form-group label="Newsletters Type" label-for="type" class="mt-2">
              <b-form-select v-model="TypeFilter" @change="fetchData" :options="TypeOptions"></b-form-select>
            </b-form-group>
          </b-form-group>
        </b-col>
        <!-- filter -->
        <b-col md="4" class="my-1">

          <b-form-group label-for="type">
            <b-form-group label="Model Type" label-cols-sm="3" label-align-sm="right" label-size="sm"
              label-for="sortBySelect" class="mb-0">
              <b-form-select v-model="TypeModelFilter" @change="fetchData" :options="TypeModelOptions"></b-form-select>
            </b-form-group>
          </b-form-group>
        </b-col>
        <b-col cols="1" class="my-1">
          <!-- print and save pdf & excel -->
          <div class="ml-auto mr-2">
            <b-dropdown style="height: 2.142rem; line-height: 0.5;" v-ripple.400="'rgba(113, 102, 240, 0.15)'" right
              variant="primary" size="sm" class="dropdown-icon-wrapper">
              <template #button-content><feather-icon icon="PrinterIcon" size="16" class="align-middle" />
              </template>
              <download-excel :data="exportItems">
                <b-dropdown-item>
                  <feather-icon icon="DownloadIcon" /> Download
                  Excel</b-dropdown-item>
              </download-excel>
              <b-dropdown-divider />
              <b-dropdown-item @click="genPDF">
                <feather-icon icon="DownloadIcon" /> Save PDF</b-dropdown-item>
              <b-dropdown-divider />
            </b-dropdown>
          </div>
        </b-col>
        <!-- start data table -->
        <b-col cols="12">
          <b-table id="tagTable" ref="admint" striped hover responsive show-empty :busy.sync="isBusy"
            class="position-relative" :per-page="perPage" @sort-changed="fetchData" 
            :items="items" :fields="fields" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection" @row-clicked="NewslettersQuickView">
            <template #cell(body)="data">
              <span v-html="data.item.body.substring(0, 8) + '..'"> </span>

            </template>


            <template #cell(filter_data)="data">
              <span v-if="data.item.filter_data != null">

                <p v-if="JSON.parse(data.item.filter_data).startDate != null">
                  <span class="text-success">Start Date:</span> {{ JSON.parse(data.item.filter_data).startDate }}
                </p>
                <p v-if="JSON.parse(data.item.filter_data).endDate != null">
                  <span class="text-success">End Date:</span> {{ JSON.parse(data.item.filter_data).endDate }}
                </p>
                <p v-if="JSON.parse(data.item.filter_data).gender != null">
                  <span class="text-success">Gender:</span> {{ JSON.parse(data.item.filter_data).gender }}
                </p>
                <p v-if="JSON.parse(data.item.filter_data).UserCategory != null">
                  <span class="text-success">User Category:</span> {{ JSON.parse(data.item.filter_data).UserCategory }}
                </p>
              </span>
            </template>

            <template #cell(created_at)="data">
              {{ data.item.created_at | formatDate }}
            </template>

            <template #cell(updated_at)="data">
              {{ data.item.updated_at | formatDate }}
            </template>
            
          </b-table>

          <b-modal v-model="modalShow" id="Newsletters-quick-view-modal" hide-footer ref="modal" centered
                title="Newsletters Details" size="lg">

                <b-row v-if="newslettersModal">
                  <b-col md="6">
                    <p class="text-primary h4 mt-1">
                      Code: <span class="h5">{{ newslettersModal.id }}</span>
                    </p>
                    <p class="text-primary h4 mt-1">
                      Filter Data:
                      <b-badge variant="primary">
                        <p v-if="JSON.parse(newslettersModal.filter_data).startDate != null">
                          <span class="text-light">Start Date:</span> {{ JSON.parse(newslettersModal.filter_data).startDate }}
                        </p>
                        <p v-if="JSON.parse(newslettersModal.filter_data).endDate != null">
                          <span class="text-light">End Date:</span> {{ JSON.parse(newslettersModal.filter_data).endDate }}
                        </p>
                        <p v-if="JSON.parse(newslettersModal.filter_data).gender != null">
                          <span class="text-light">Gender:</span> {{ JSON.parse(newslettersModal.filter_data).gender }}
                        </p>
                        <p v-if="JSON.parse(newslettersModal.filter_data).UserCategory != null">
                          <span class="text-light">User Category:</span> {{ JSON.parse(
                            newslettersModal.filter_data).UserCategory }}
                        </p>
                      </b-badge>
                    </p>
                    <p class="text-primary h4 mt-1">
                      Model Type: <span class="h5">{{ newslettersModal.model_type }}</span>
                    </p>
                    <p class="text-primary h4 mt-1" v-if="newslettersModal.type == 'email'">
                      Title:
                      <b-badge variant="warning">{{ newslettersModal.title }}</b-badge>
                    </p>
                    <p class="text-primary h4 mt-1">
                      Body:
                      <b-badge variant="light" class="p-1" > 
                        <span class="text-primary" style=" white-space: pre-wrap !important;" v-html="newslettersModal.body"> </span>
                      </b-badge>
                    </p>
                    <p class="text-primary h4 mt-1">
                      Type:
                      <b-badge variant="info">{{ newslettersModal.type }}</b-badge>
                    </p>
                    <p class="text-primary h4 mt-1">
                      Created At:
                      <b-badge variant="info">{{ newslettersModal.created_at  | formatDate}}</b-badge>
                    </p>
                    <p class="text-primary h4 mt-1">
                      Updated At:
                      <b-badge variant="info">{{ newslettersModal.updated_at  | formatDate}}</b-badge>
                    </p>

                  </b-col>
                </b-row>
              </b-modal>
        </b-col>
      </b-row>
      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
        <!-- page length -->
        <b-form-group class="mb-0">
          <label class="d-inline-block text-sm-left mr-50">Per page</label>
          <b-form-select id="perPageSelect" @change="fetchData" v-model="perPage" size="sm" :options="pageOptions"
            class="w-50" />
        </b-form-group>
        <!-- pagination -->
        <div>
          <b-pagination @input="fetchData()" aria-controls="CategoryTable" v-model="currentPage" :total-rows="rows"
            :per-page="perPage" align="center" class="my-0" />
        </div>
      </b-card-body>
    </b-card>
  </div>
</template>
  
<script>

export default {

  data() {
    return {
      authPermissions: null,
      deactive: '0',
      areaID: null,
      deletedArea: '0',
      showDismissibleAlert: false,
      errors_back: [],
      errors_delete: {},
      perPage: 25,
      pageOptions: [25, 50, 100],
      totalRows: 1,
      isBusy: false,
      currentPage: 1,
      sortBy: 'created_at',
      sortDesc: true,
      sortDirection: 'desc',
      filter: '',
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      newslettersModal: '',
      TypeFilter: '',
      TypeModelFilter: '',
      TypeOptions: [
        { value: 'email', text: 'Email' },
        { value: 'sms', text: 'SMS' },
      ],


      TypeModelOptions: [
        { value: 'users', text: 'Users' },
        { value: 'admins', text: 'Admins' },
        { value: 'sellers', text: 'Sellers' },

      ],
      AllBody: '',
      modalShow: false,

      fields: [
        {
          key: 'id',
          label: 'Id',
          sortable: true,
        },
        {
          key: 'filter_data',
          label: 'filter Data',
        },
        {
          key: 'model_type',
          label: 'model type',
        },
        {
          key: 'title',
          label: 'title',
        },
        {
          key: 'body',
          label: 'body',
        },

        {
          key: 'type',
          label: 'type',
        },
        {
          key: 'created_at',
          label: 'Created_At',
          sortable: true,
        },
        {
          key: 'updated_at',
          label: 'Updated_At',
          sortable: true,
        },

      ],
      items: [],
      exportItems: [],
    }
  },
  created() {
    this.authPermissions = this.$store.state.authPermissions
    // request tags index
    this.fetchData()
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({
          text: f.label,
          value: f.key,
        }))
    },
    rows() {
      return this.totalRows
    },
  },
  watch: {
    // sortDirection: function () {
    //   this.sortingTable()
    // },
    // sortBy: function () {
    //   this.sortingTable()
    // },
    filter: function () {
      if (this.filter == '' || this.filter == null) {
        this.fetchData()
      }
    },
  },
  methods: {
    // fetch data of areas
    fetchData() {
      var url = null
      if (this.TypeFilter == '') {
        url =
          'newsletters/index/' +
          this.perPage.toString() +
          '/sortBy-' +
          this.sortBy +
          '-' +
          this.sortDirection +
          '?page=' +
          this.currentPage
      } else {
        url =
          'newsletters/index/' +
          this.perPage.toString() +
          '/sortBy-' +
          this.sortBy +
          '-' +
          this.sortDirection +
          '/' +
          this.TypeFilter +
          '?page=' +
          this.currentPage
      }
      const promise = axios.post(url, { "model_type": this.TypeModelFilter })
      return promise
        .then((result) => {
          this.totalRows = result.data.data.total;
          this.items.length = 0;
          this.items = result.data.data.data;
          this.exportItems = this.items;
          this.$refs.admint.refresh();
          return this.items
        })
        .catch((err) => {
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    // sort function
    sortingTable() {
      this.currentPage = 1
      if (this.sortDesc == true) {
        this.sortDirection = 'desc'
      } else {
        this.sortDirection = 'asc'
      }
      var url = null
      if (this.filter == '') {
        url =
          'newsletters/index/' +
          this.perPage.toString() +
          '/sortBy-' +
          this.sortBy +
          '-' +
          this.sortDirection +
          '?page=' +
          this.currentPage
      } else {
        url =
          'newsletters/index/' +
          this.perPage.toString() +
          '/sortBy-' +
          this.sortBy +
          '-' +
          this.sortDirection +
          '/' +
          this.TypeFilter +
          '?page=' +
          this.currentPage
      }
      const promise = axios.post(url, { "model_type": this.TypeModelFilter })
      return promise
        .then((result) => {

          this.totalRows = result.data.data.meta.total
          this.items.length = 0
          this.items = result.data.data.data
          this.exportItems = this.items
          this.$refs.admint.refresh()
          return this.items
        })
        .catch((err) => {
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },

    // delete function to delete area
    deleteData(id) {
      this.$bvModal
        .msgBoxConfirm(
          'Please confirm that you want to delete post category.',
          {
            title: 'Are You Sure?',
            size: 'sm',
            okVariant: 'danger',
            okTitle: 'Yes',
            cancelTitle: 'No',
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          if (value == true) {
            axios
              .delete('post-categories/' + id)
              .then((result) => {
                this.$swal({
                  position: 'center',
                  icon: 'success',
                  title: 'Your work has been saved',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.fetchData()
              })
              .catch((err) => {
                this.$swal({
                  position: 'center',
                  icon: 'error',
                  title: 'Error!',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.errors_back.length = 0
                if (err.response.data.data != null) {
                  if (this.isString(err.response.data.data)) {
                    this.errors_back.push({
                      error: err.response.data.data,
                    })
                  } else {
                    this.errors_back = err.response.data.data
                  }
                  this.showDismissibleAlert = true
                } else {
                  this.errors_back = []
                  this.errors_back.push({
                    error: 'internal server error',
                  })
                  this.showDismissibleAlert = true
                }
              })
          }
        })
    },

    NewslettersQuickView(item, index, event) {
      this.$bvModal.show('Newsletters-quick-view-modal')
      this.newslettersModal = item
    },
    genPDF() {
      const { jsPDF } = require("jspdf");
      const doc = new jsPDF()
      let print_body = []
      for (let index = 0; index < this.items.length; index++) {
        print_body[index] = [
          this.items[index].id,
          this.items[index].filter_data,
          this.items[index].model_type,
          this.items[index].title,
          this.items[index].type,
          this.items[index].created_at,
          this.items[index].updated_at,
        ]
      }
      doc.autoTable({
        head: [['ID', 'Name', 'Active', 'CREATED_AT', 'UPDATED_AT']],
        body: print_body,
      })
      doc.save('table.pdf')
    },
    isString(value) {
      return typeof value === 'string' || value instanceof String
    },
  },
}
</script>



<style>
.modal-backdrop {
  opacity: 0.1;
  background-color: #22292f;
  position: fixed;
  top: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
}

.Newsletterss-table {
  max-height: 70vh !important;
}

.m-50 {
  margin-left: 50px;
}

.badge{
  white-space: pre-wrap !important;
}

</style>
  